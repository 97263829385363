export const isInteger = (value) => {
  return Number.isInteger(Number(value));
};

export const chunkArray = (array, size) => {
  const chunks = [];
  for (let i = 0; i < array.length; i += size) {
    chunks.push(array.slice(i, i + size));
  }
  return chunks;
};

export const parseAndValidateFile = async ({ file, callback }) => {
  const parsedFile = await callback(file);
  const rows = parsedFile.split("\n").map((row) => row.trim());
  const errors = [];

  rows.forEach((row, index) => {
    if (!row) {
      errors.push({
        row: index + 1,
        value: row,
        error: "Строка не должна быть пустой",
      });
    } else if (!isInteger(row)) {
      errors.push({
        row: index + 1,
        value: row,
        error: "Значение должно быть целым числом",
      });
    }
  });
  return {
    ids: rows,
    errors,
  };
};

export const sendMessageToServiceWorker = async (message) => {
  if (!("serviceWorker" in navigator)) {
    throw new Error("Service Worker не поддерживается в этом браузере.");
  }
  const registration = await navigator.serviceWorker.ready;
  if (!registration.active) {
    throw new Error(
      "Service Worker найден, но он не активен. Возможно, он еще загружается."
    );
  }
  registration.active.postMessage(message);
};
