<template>
  <v-dialog
    :value="value"
    max-width="1200px"
    @click:outside="handleClose"
    @keydown.esc="handleClose"
  >
    <v-card>
      <v-toolbar dense flat>
        <v-toolbar-title class="text-h5 rm-dialog-title">{{
          title || "Ошибки при синхронизации"
        }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <div v-show="parseFileErrors.length" class="mt-2">
          <v-alert type="error" outlined class="alert">
            <div v-for="error in parseFileErrors" :key="error.row">
              <p class="mb-1">
                <strong>Строка {{ error.row }}:</strong> "{{ error.value }}" -
                {{ error.error }}
              </p>
            </div>
          </v-alert>
        </div>

        <div v-if="Object.keys(syncErrors).length" class="mt-2">
          <v-alert type="error" outlined class="alert">
            <div v-for="(err, userId) of syncErrors" :key="userId">
              <p class="mb-1">
                <strong>Talent ID {{ userId }}:</strong>
                {{ err }}
              </p>
            </div>
          </v-alert>
        </div>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-btn color="primary" @click="handleClose">Закрыть</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    title: String,
    parseFileErrors: {
      type: Array,
      default: () => [],
    },
    syncErrors: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    handleClose() {
      this.$emit("input", false);
    },
  },
};
</script>
<style scoped lang="scss">
.alert {
  max-height: 300px;
  overflow-y: auto;
}
</style>
